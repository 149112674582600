import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { fetchParkingRightWithVersion } from 'api/tickets';
import { ParkingRightWithVersionsDTO } from 'api/tickets/types';
import ErrorPage from 'tefps/RecoursesV2/commons/ErrorPage';
import Loading from 'tefps/RecoursesV2/commons/Loading';
import ReturnButton from 'tefps/RecoursesV2/commons/ReturnButton';

import TicketPage from './TicketPage';

const { _tg } = window.loadTranslations(__filename);

const TicketDetail = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error>();
  const [ticket, setTicket] = useState<
    ParkingRightWithVersionsDTO | undefined
  >();

  const { ticketId } = useParams<{ ticketId: string }>();

  const loadTicket = useCallback(() => {
    setLoading(true);
    fetchParkingRightWithVersion(ticketId)
      .finally(() => {
        setLoading(false);
      })
      .then(fetchedTicket => {
        setTicket(fetchedTicket);
      })
      .catch(err => {
        setError(err);
      });
  }, [ticketId]);

  useEffect(() => {
    loadTicket();
  }, [loadTicket]);

  if (error) {
    return (
      <ErrorPage
        message={_tg('feedback.error.fetchControl')}
        error={error}
        navContent={
          <ReturnButton
            href="#/controls/list"
            label={_tg('action.backToList')}
          />
        }
      />
    );
  }

  if (!loading && ticket) {
    return (
      <TicketPage
        ticket={ticket}
        reload={loadTicket}
        onUpdate={msg => {
          setError(new Error(msg));
        }}
      />
    );
  }

  return <Loading />;
};

export default TicketDetail;
