import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import MissNotFound from 'commons/MissNotFound';
import { getConfigState } from 'config/duck';

import TicketsList from './List';
import TicketDetail from './Detail';

const Tickets = ({
  match,
  enabled,
  enabledWithMessage,
}: RouteComponentProps & {
  enabled: boolean;
  enabledWithMessage: boolean;
}) => (
  <div style={{ height: '100%' }}>
    <Switch>
      {!enabled && !enabledWithMessage && <MissNotFound />}
      {!enabled && <Route path={`${match.url}`} component={TicketsList} />}
      <Redirect exact path={`${match.url}`} to={`${match.url}/list`} />
      <Route path={`${match.url}/list`} exact component={TicketsList} />
      <Route
        path={`${match.url}/detail/:ticketId`}
        exact
        component={TicketDetail}
      />
      <MissNotFound />
    </Switch>
  </div>
);

export default connect(state => {
  const {
    modulesConfiguration: {
      tv: { enabled, displayed },
    },
  } = getConfigState(state);
  return {
    enabled,
    enabledWithMessage: !enabled && displayed,
  };
})(Tickets);
