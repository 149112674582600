import * as React from 'react';
import moment from 'moment';

import { SubscriberDTO } from '@cvfm-front/tefps-types';
import { ItemIdName } from 'api/commonTypes';
import RecoverPasswordAction from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/RecoverPasswordAction';
import EditEmailAction from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/EditEmailAction';
import CreateAccountActionV0 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/CreateAccountActionV0';
import CreateSubscriptionActionV0 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/CreateSubscriptionActionV0';
import CancelSubscriptionActionV0 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/CancelSubscriptionActionV0';
import EditPlateActionV0 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/EditPlateActionV0';
import AcceptSubscriptionActionV0 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/AcceptSubscriptionActionV0';
import RejectSubscriptionActionV0 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/RejectSubscriptionActionV0';
import RemoveSubscriptionActionV0 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/RemoveSubscriptionActionV0';
import StartSubscriptionActionV0 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/StartSubscriptionActionV0';
import EndSubscriptionActionV0 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/EndSubscriptionActionV0';
import MessageActionV0 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/MessageActionV0';
import TransferSubscriptionActionV0 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/TransferSubscriptionActionV0';
import SendSignInMailActionV0 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/SendSignInMailActionV0';
import SendSubscriptionRenewalReminderActionV0 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/SendSubscriptionRenewalReminderActionV0';
import SendSmsActionV0 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/SendSmsActionV0';
import SendSmsActionV1 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/SendSmsActionV1';
import SendEmailActionV0 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/SendEmailActionV0';
import SendEmailActionV1 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/SendEmailActionV1';
import CreateSubscriptionActionV1 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/CreateSubscriptionActionV1';
import CancelSubscriptionActionV1 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/CancelSubscriptionActionV1';
import EditPlateActionV1 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/EditPlateActionV1';
import EditPlateActionV2 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/EditPlateActionV2';
import AcceptSubscriptionActionV1 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/AcceptSubscriptionActionV1';
import RejectSubscriptionActionV1 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/RejectSubscriptionActionV1';
import StartSubscriptionActionV1 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/StartSubscriptionActionV1';
import EndSubscriptionActionV1 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/EndSubscriptionActionV1';
import TransferSubscriptionActionV1 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/TransferSubscriptionActionV1';
import SendSubscriptionRenewalReminderActionV1 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/SendSubscriptionRenewalReminderActionV1';
import SendSubscriptionRenewalReminderActionV2 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/SendSubscriptionRenewalReminderActionV2';
import CreateParkingRightRequestActionV0 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/CreateParkingRightRequestActionV0';
import StartParkingRightRequestActionV0 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/StartParkingRightRequestActionV0';
import CancelParkingRightRequestActionV0 from 'tefps/Beneficiaries/Subscribers/DetailSubscriber/History/CancelParkingRightRequestActionV0';

import SubscriberAcceptBundleAction from './SubscriberAcceptBundleAction';
import SubscriberRejectEligibilityAction from './SubscriberRejectEligibilityAction';
import SubscriberRejectBundleAction from './SubscriberRejectBundleAction';
import SubscriberAcceptEligibilityAction from './SubscriberAcceptEligibilityAction';
import SubscriberCancelBundleAction from './SubscriberCancelBundleAction';
import SubscriberCancelEligibilityAction from './SubscriberCancelEligibilityAction';
import SubscriberCreateBundleAction from './SubscriberCreateBundleAction';
import SubscriberCreateEligibilityAction from './SubscriberCreateEligibilityAction';
import SubscriberStartBundleAction from './SubscriberStartBundleAction';
import SubscriberStartEligibilityAction from './SubscriberStartEligibilityAction';
import SubscriberEndEligibilityAction from './SubscriberEndEligibilityAction';
import SubscriberEndBundleAction from './SubscriberEndBundleAction';
import SubscriberTransferAction from './SubscriberTransferAction';
import SubscriberCreateClaimAction from './SubscriberCreateClaimAction';
import SubscriberClaimDecisionAction from './SubscriberClaimDecisionAction';
import SubscriberClaimEvidenceAction from './SubscriberClaimEvidenceAction';
import SubscriberEvidenceAction from './SubscriberEvidenceAction';
import SubscriberEditZoneAction from './SubscriberEditZoneAction';

const ACTION_DOMAIN =
  'fr.polyconseil.smartcity.cvfm.core.subscription.data.model.subscriber.action.';
const ACTION_V2_DOMAIN =
  'fr.polyconseil.smartcity.cvfm.core.subscription.v2.model.action.';
const CreateAccountActionV0Type = `${ACTION_DOMAIN}CreateAccountActionV0`;
const EditPlateActionV0Type = `${ACTION_DOMAIN}EditPlateActionV0`;
const AcceptSubscriptionActionV0Type = `${ACTION_DOMAIN}AcceptSubscriptionActionV0`;
const RejectSubscriptionActionV0Type = `${ACTION_DOMAIN}RejectSubscriptionActionV0`;
const RemoveSubscriptionActionV0Type = `${ACTION_DOMAIN}RemoveSubscriptionActionV0`;
const StartSubscriptionActionV0Type = `${ACTION_DOMAIN}StartSubscriptionActionV0`;
const TransferSubscriptionActionV0Type = `${ACTION_DOMAIN}TransferSubscriptionActionV0`;
const CreateSubscriptionActionV0Type = `${ACTION_DOMAIN}CreateSubscriptionActionV0`;
const CancelSubscriptionActionV0Type = `${ACTION_DOMAIN}CancelSubscriptionActionV0`;
const SendSignInMailActionV0Type = `${ACTION_DOMAIN}SendSignInMailActionV0`;
const SendSubscriptionRenewalReminderActionV0Type = `${ACTION_DOMAIN}SendSubscriptionRenewalReminderActionV0`;
const SendSmsActionV0Type = `${ACTION_DOMAIN}SendSmsActionV0`;
const SendSmsActionV1Type = `${ACTION_DOMAIN}SendSmsActionV1`;
const SendEmailActionV0Type = `${ACTION_DOMAIN}SendEmailActionV0`;
const SendEmailActionV1Type = `${ACTION_DOMAIN}SendEmailActionV1`;
const CreateParkingRightRequestActionV0Type = `${ACTION_DOMAIN}CreateParkingRightRequestActionV0`;
const StartParkingRightRequestActionV0Type = `${ACTION_DOMAIN}StartParkingRightRequestActionV0`;
const CancelParkingRightRequestActionV0Type = `${ACTION_DOMAIN}CancelParkingRightRequestActionV0`;

const AcceptSubscriptionActionV1Type = `${ACTION_DOMAIN}AcceptSubscriptionActionV1`;
const CancelSubscriptionActionV1Type = `${ACTION_DOMAIN}CancelSubscriptionActionV1`;
const CreateSubscriptionActionV1Type = `${ACTION_DOMAIN}CreateSubscriptionActionV1`;
const EditPlateActionV1Type = `${ACTION_DOMAIN}EditPlateActionV1`;
const EditPlateActionV2Type = `${ACTION_DOMAIN}EditPlateActionV2`;
const RejectSubscriptionActionV1Type = `${ACTION_DOMAIN}RejectSubscriptionActionV1`;
const SendSubscriptionRenewalReminderActionV1Type = `${ACTION_DOMAIN}SendSubscriptionRenewalReminderActionV1`;
const StartSubscriptionActionV1Type = `${ACTION_DOMAIN}StartSubscriptionActionV1`;
const TransferSubscriptionActionV1Type = `${ACTION_DOMAIN}TransferSubscriptionActionV1`;

const SendSubscriptionRenewalReminderActionV2Type = `${ACTION_DOMAIN}SendSubscriptionRenewalReminderActionV2`;

const RecoverPasswordActionType = `${ACTION_DOMAIN}RecoverPasswordAction`;
const EditEmailActionType = `${ACTION_DOMAIN}EditEmailAction`;

const AcceptBundleActionType = `${ACTION_V2_DOMAIN}AcceptBundleAction`;
const AcceptEligibilityActionType = `${ACTION_V2_DOMAIN}AcceptEligibilityAction`;
const CancelBundleActionType = `${ACTION_V2_DOMAIN}CancelBundleAction`;
const CancelEligibilityActionType = `${ACTION_V2_DOMAIN}CancelEligibilityAction`;
const CreateBundleActionType = `${ACTION_V2_DOMAIN}CreateBundleAction`;
const CreateEligibilityActionType = `${ACTION_V2_DOMAIN}CreateEligibilityAction`;
const StartBundleActionType = `${ACTION_V2_DOMAIN}StartBundleAction`;
const StartEligibilityActionType = `${ACTION_V2_DOMAIN}StartEligibilityAction`;
const RejectBundleActionType = `${ACTION_V2_DOMAIN}RejectBundleAction`;
const RejectEligibilityActionType = `${ACTION_V2_DOMAIN}RejectEligibilityAction`;
const TransferActionType = `${ACTION_V2_DOMAIN}TransferOrderAction`;
const CreateClaimActionType = `${ACTION_V2_DOMAIN}CreateClaimAction`;
const ClaimDecisionActionType = `${ACTION_V2_DOMAIN}ClaimDecisionHistoryAction`;
const ClaimEvidenceActionType = `${ACTION_V2_DOMAIN}ClaimEvidenceHistoryAction`;
const EvidenceActionType = `${ACTION_V2_DOMAIN}EvidenceHistoryAction`;
const EditZoneActionType = `${ACTION_V2_DOMAIN}EditZoneAction`;

type ActionsComponentsValueSimple = {
  component: React.FunctionComponent<any>;
};

type ActionsComponentsValueDated = {
  componentStart: React.FunctionComponent<any>;
  componentEnd: React.FunctionComponent<any>;
};
type ActionsComponentsValue =
  | ActionsComponentsValueSimple
  | ActionsComponentsValueDated;

const ACTIONS_COMPONENTS: { [k: string]: ActionsComponentsValue } = {
  [CreateAccountActionV0Type]: { component: CreateAccountActionV0 },
  [EditPlateActionV0Type]: { component: EditPlateActionV0 },
  [AcceptSubscriptionActionV0Type]: { component: AcceptSubscriptionActionV0 },
  [RejectSubscriptionActionV0Type]: { component: RejectSubscriptionActionV0 },
  [RemoveSubscriptionActionV0Type]: { component: RemoveSubscriptionActionV0 },
  [StartSubscriptionActionV0Type]: {
    componentStart: StartSubscriptionActionV0,
    componentEnd: EndSubscriptionActionV0,
  },
  [TransferSubscriptionActionV0Type]: {
    component: TransferSubscriptionActionV0,
  },
  [CreateSubscriptionActionV0Type]: {
    component: CreateSubscriptionActionV0,
  },
  [CancelSubscriptionActionV0Type]: {
    component: CancelSubscriptionActionV0,
  },
  [SendSignInMailActionV0Type]: { component: SendSignInMailActionV0 },
  [SendSubscriptionRenewalReminderActionV0Type]: {
    component: SendSubscriptionRenewalReminderActionV0,
  },
  [SendSmsActionV0Type]: { component: SendSmsActionV0 },
  [SendSmsActionV1Type]: { component: SendSmsActionV1 },
  [SendEmailActionV0Type]: { component: SendEmailActionV0 },
  [SendEmailActionV1Type]: { component: SendEmailActionV1 },
  [AcceptSubscriptionActionV1Type]: {
    component: AcceptSubscriptionActionV1,
  },
  [CancelSubscriptionActionV1Type]: {
    component: CancelSubscriptionActionV1,
  },
  [CreateSubscriptionActionV1Type]: {
    component: CreateSubscriptionActionV1,
  },
  [EditPlateActionV1Type]: { component: EditPlateActionV1 },
  [EditPlateActionV2Type]: { component: EditPlateActionV2 },
  [RejectSubscriptionActionV1Type]: {
    component: RejectSubscriptionActionV1,
  },
  [SendSubscriptionRenewalReminderActionV1Type]: {
    component: SendSubscriptionRenewalReminderActionV1,
  },
  [TransferSubscriptionActionV1Type]: {
    component: TransferSubscriptionActionV1,
  },
  [StartSubscriptionActionV1Type]: {
    componentStart: StartSubscriptionActionV1,
    componentEnd: EndSubscriptionActionV1,
  },
  [SendSubscriptionRenewalReminderActionV2Type]: {
    component: SendSubscriptionRenewalReminderActionV2,
  },
  [CreateParkingRightRequestActionV0Type]: {
    component: CreateParkingRightRequestActionV0,
  },
  [StartParkingRightRequestActionV0Type]: {
    component: StartParkingRightRequestActionV0,
  },
  [CancelParkingRightRequestActionV0Type]: {
    component: CancelParkingRightRequestActionV0,
  },
  [RecoverPasswordActionType]: {
    component: RecoverPasswordAction,
  },
  [EditEmailActionType]: {
    component: EditEmailAction,
  },
  [AcceptBundleActionType]: {
    component: SubscriberAcceptBundleAction,
  },
  [RejectBundleActionType]: {
    component: SubscriberRejectBundleAction,
  },
  [AcceptEligibilityActionType]: {
    component: SubscriberAcceptEligibilityAction,
  },
  [RejectEligibilityActionType]: {
    component: SubscriberRejectEligibilityAction,
  },
  [CancelBundleActionType]: { component: SubscriberCancelBundleAction },
  [CancelEligibilityActionType]: {
    component: SubscriberCancelEligibilityAction,
  },
  [CreateBundleActionType]: {
    component: SubscriberCreateBundleAction,
  },
  [CreateEligibilityActionType]: {
    component: SubscriberCreateEligibilityAction,
  },
  [TransferActionType]: {
    component: SubscriberTransferAction,
  },
  [StartBundleActionType]: {
    componentStart: SubscriberStartBundleAction,
    componentEnd: SubscriberEndBundleAction,
  },
  [StartEligibilityActionType]: {
    componentStart: SubscriberStartEligibilityAction,
    componentEnd: SubscriberEndEligibilityAction,
  },
  [CreateClaimActionType]: {
    component: SubscriberCreateClaimAction,
  },
  [ClaimDecisionActionType]: {
    component: SubscriberClaimDecisionAction,
  },
  [ClaimEvidenceActionType]: {
    component: SubscriberClaimEvidenceAction,
  },
  [EvidenceActionType]: {
    component: SubscriberEvidenceAction,
  },
  [EditZoneActionType]: {
    component: SubscriberEditZoneAction,
  },
};

export type History = { date: any; message: React.ReactElement<any> };

export const computeHistory = (
  subscriber: SubscriberDTO | null | undefined,
  productItems: Array<ItemIdName>
): Array<History> => {
  const history: Array<History> = [];

  if (subscriber && productItems && subscriber.history) {
    const planNames = productItems.reduce((result, item) => {
      result[item.id] = item.name;
      return result;
    }, {});

    if (subscriber.history) {
      subscriber.history.forEach(item => {
        if (item.action.planId) {
          item.action.planName = planNames[item.action.planId]
            ? planNames[item.action.planId]
            : '';
        }

        const actionComponent: ActionsComponentsValue =
          ACTIONS_COMPONENTS[item.action.class];
        if (actionComponent) {
          if (
            item.action.class === StartSubscriptionActionV0Type ||
            item.action.class === StartSubscriptionActionV1Type ||
            item.action.class === StartBundleActionType ||
            item.action.class === StartEligibilityActionType
          ) {
            const ComponentStart = (actionComponent as ActionsComponentsValueDated)
              .componentStart;
            history.push({
              date: moment(item.action.startDate),
              message: (
                <ComponentStart action={item.action} subscriber={subscriber} />
              ),
            });

            if (moment(item.action.endDate).isBefore(moment())) {
              const ComponentEnd = (actionComponent as ActionsComponentsValueDated)
                .componentEnd;
              history.push({
                date: moment(item.action.endDate),
                message: (
                  <ComponentEnd action={item.action} subscriber={subscriber} />
                ),
              });
            }
          } else {
            const Component = (actionComponent as ActionsComponentsValueSimple)
              .component;
            history.push({
              date: moment(item.when),
              message: (
                <Component action={item.action} subscriber={subscriber} />
              ),
            });
          }
        }
      });
    }
  }

  if (subscriber && subscriber.messages) {
    subscriber.messages.forEach(message => {
      history.push({
        date: moment(message.date),
        message: <MessageActionV0 action={{ subject: message.subject }} />,
      });
    });
  }

  return history.sort((a, b) => a.date.diff(b.date));
};
