import React from 'react';

import { ProductType } from '@cvfm-front/tefps-types';

import SubscriberSimpleOrderHistoryAction from './SubscriberSimpleOrderHistoryAction';

const { _t } = window.loadTranslations(__filename);

interface Props {
  action: {
    planName: string;
    productType: ProductType;
    fromZones: string[];
    toZones: string[];
  };
}

function SubscriberEditZoneAction({ action }: Props): JSX.Element {
  return (
    <SubscriberSimpleOrderHistoryAction
      message={_t('element.content', {
        planName: action.planName,
        oldZones: action.fromZones.join(', '),
        newZones: action.toZones.join(', '),
        context: action.productType,
      })}
      action={action}
    />
  );
}

export default SubscriberEditZoneAction;
