import React, { CSSProperties } from 'react';

import SimpleTable from 'commons/SimpleTable';
import Price from 'commons/Price';
import Date from 'commons/Date';
import { ParkingRightOverviewDTO } from 'api/tickets/types';

import { buildDisplayingPlate } from './utils';

const { _tg } = window.loadTranslations(__filename);

const STYLE_LINK: CSSProperties = {
  height: 50,
  width: '100%',
};

const STYLE_HOVERED: CSSProperties = {
  ...STYLE_LINK,
  color: 'white',
  cursor: 'pointer',
};

type Props = {
  items: Array<ParkingRightOverviewDTO>;
  loadMoreRows: (arg0: { startIndex: number }) => Promise<void>;
  onUpdateSort: (colNumber: number, increasingOrder: boolean) => Promise<void>;
  remoteRowCount?: number;
  colSorted?: number;
  sortOrder?: boolean;
};

type State = {
  hovered: string | null | undefined;
};

function getStyle(id: string, hovered: string | null | undefined) {
  return id === hovered ? STYLE_HOVERED : STYLE_LINK;
}

class TicketTable extends React.Component<Props, State> {
  tableCols = [
    {
      label: _tg('field.ticket.ticketNumber_2'),
      width: 330,
      grow: 3,
      onSort: true,
    },
    {
      label: _tg('field.vehicle.licensePlate'),
      width: 200,
      onSort: true,
    },
    { label: _tg('field.date.startValidity'), width: 200, onSort: true },
    {
      label: _tg('field.date.endValidity'),
      width: 200,
      onSort: true,
    },
    { label: _tg('field.address.zone'), width: 150, onSort: true },
    { label: _tg('commons.tariff'), width: 75, onSort: true },
  ];

  constructor(props: Props) {
    super(props);
    this.state = {
      hovered: null,
    };
  }

  handleMouseLeave = (): void => this.setState({ hovered: null });
  handleMouseEnter = ({ currentTarget }: React.MouseEvent<any>): void =>
    this.setState({ hovered: currentTarget.dataset.ticketId });

  render(): JSX.Element {
    const {
      loadMoreRows,
      onUpdateSort,
      remoteRowCount,
      colSorted,
      sortOrder,
      items,
    } = this.props;
    const { hovered } = this.state;

    return (
      <SimpleTable
        style={{ marginTop: 25 }}
        cols={this.tableCols}
        rowHeight={50}
        header
        onSort={onUpdateSort}
        colSorted={colSorted}
        sortOrder={sortOrder}
        loadMoreRows={loadMoreRows}
        remoteRowCount={remoteRowCount}
        itemsRenderer={(tv: ParkingRightOverviewDTO) => [
          <span
            style={getStyle(tv.ticketId, hovered)}
            data-ticket-id={tv.ticketId}
            onMouseLeave={this.handleMouseLeave}
            onMouseEnter={this.handleMouseEnter}
          >
            <a href={location.hash.replace(/[^/]*$/, `detail/${tv.ticketId}`)}>
              {tv.ticketId}
            </a>
          </span>,
          buildDisplayingPlate(tv.plate),
          <Date datetime={tv.startDatetime} />,
          <Date datetime={tv.endDatetime} />,
          tv.zoneId,
          <Price price={tv.price} />,
        ]}
        items={items}
      />
    );
  }
}

export default TicketTable;
