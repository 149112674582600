import _debounce from 'lodash.debounce';

import { Watcher } from '@cvfm-front/commons-utils';
import {
  OrderSortParameters,
  SimpleSubscriberDTO,
  SortOrder,
  SubscribersSearchResultDTO,
  SubscriberAccountType,
  SubscriberFilters,
} from '@cvfm-front/tefps-types';
import { SubscribersSearchCriteria } from 'tefps/Beneficiaries/Subscribers/types';

import { searchSubscribers } from '../../api/cvfm-core-subscription/subscriber';
import { DEFAULT_FILTER_DEBOUNCE } from 'commons/const';

function SubscriberFilterService() {
  const { getValue: getFilters, setValue, watchValue: watchFilters } = Watcher<
    SubscribersSearchCriteria
  >({
    zoneIds: new Set<string>(),
    professionalZoneIds: new Set<string>(),
    accountTypes: new Set<SubscriberAccountType>(),
  });
  const {
    getValue: getSortParameter,
    setValue: setSortParameter,
    watchValue: watchSortParameter,
  } = Watcher<OrderSortParameters>({
    page: 0,
    index: 0,
    sortOrder: SortOrder.ASC,
    field: undefined,
  });
  const {
    getValue: getResults,
    setValue: setResults,
    watchValue: watchResults,
  } = Watcher<SubscribersSearchResultDTO | undefined>(undefined);
  const {
    getValue: getSubscribers,
    setValue: setSubscribers,
    watchValue: watchSubscribers,
  } = Watcher<SimpleSubscriberDTO[]>([]);
  const searchPageMaxRecords = 25;

  function buildSearchParameters(maxRecords?: number): SubscriberFilters {
    const search: SubscriberFilters = {
      page: getSortParameter().page,
      maxRecords: maxRecords || searchPageMaxRecords,
      order: getSortParameter().sortOrder,
      sort: getSortParameter()?.field || 'lastName',
      ...getFilters(),
      id: getFilters().subscriberId,
      zoneIds: [...getFilters().zoneIds],
      professionalZoneIds: [...getFilters().professionalZoneIds],
      accountTypes: [...getFilters().accountTypes],
    };
    return search;
  }

  async function loadSubscribers(
    maxRecords?: number
  ): Promise<SubscribersSearchResultDTO> {
    const result = await searchSubscribers(buildSearchParameters(maxRecords));
    setResults(result);
    if (getSortParameter().page === 0) {
      setSubscribers(result.subscribers);
    } else {
      setSubscribers([...getSubscribers(), ...result.subscribers]);
    }
    if (getSortParameter().page === 0) {
      setSubscribers(result.subscribers);
    } else {
      setSubscribers([...getSubscribers(), ...result.subscribers]);
    }
    return result;
  }

  // Debounce fonction pour les changements de filtres
  const debounceFetchSubs = _debounce(async () => {
    await loadSubscribers();
  }, DEFAULT_FILTER_DEBOUNCE);

  function setFilters(
    callback: (filters: SubscribersSearchCriteria) => SubscribersSearchCriteria
  ) {
    const newFilters = callback(getFilters());
    setValue(newFilters);
    setSortParameter({ ...getSortParameter(), page: 0 });
    void debounceFetchSubs();
  }

  function updateSortParameters(
    callback: (sortParameters: OrderSortParameters) => OrderSortParameters
  ) {
    const newSortParameters = callback(getSortParameter());
    setSortParameter({ ...newSortParameters, page: 0 });
    void debounceFetchSubs();
  }

  function moreSubscribers(page: number) {
    setSortParameter({ ...getSortParameter(), page });
    void loadSubscribers();
  }

  return {
    getFilters,
    setFilters,
    searchPageMaxRecords,
    watchFilters,
    getSortParameter,
    updateSortParameters,
    moreSubscribers,
    watchSortParameter,
    buildSearchParameters,
    getResults,
    watchResults,
    getSubscribers,
    loadSubscribers,
    setSubscribers,
    watchSubscribers,
  };
}

export default SubscriberFilterService();
