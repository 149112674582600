import React, { useState } from 'react';
import { compact } from 'lodash';
import { Card, CircularProgress } from 'material-ui';
import { connect } from 'react-redux';

import { Container, Flex } from '@cvfm-front/commons-ui';
import { Version, VersionsDisplayer } from '@cvfm-front/tefps-ui';
import {
  ParkingRightVersionDTO,
  ParkingRightWithVersionsDTO,
} from 'api/tickets/types';
import ReturnButton from 'tefps/RecoursesV2/commons/ReturnButton';
import FakeInputBlock from 'commons/FakeInputBlock';
import FpsNumber from 'commons/FpsNumber';
import Date, { formatDatetime } from 'commons/Date';
import { BKG_PINK } from 'theme';
import Price, { formatCtsPrice } from 'commons/Price';
import CopyValueButton from 'commons/CopyValueButton';
import BoButton from 'facade/BoButton';
import { getProofOfPayment } from 'api/parkingMeter';
import downloadFile from 'commons/DownloadFile';
import { getConfigState } from 'config/duck';
import ErrorBlock from 'commons/ErrorBlock';

import PlateRow from '../List/TicketDetail/PlateRow';
import { buildDisplayingPlate } from '../List/utils';

const { _tg } = window.loadTranslations(__filename);

const parkingRightDTOToVersion = (
  dto: ParkingRightVersionDTO,
  index: number
): Version => {
  return {
    title: _tg('field.version', { version: index + 1 }),
    fields: compact([
      {
        type: 'string',
        id: `zone-${index}`,
        label: _tg('field.address.zone'),
        value: dto.zoneId,
      },
      {
        type: 'string',
        id: `plate-${index}`,
        label: _tg('field.vehicle.plate'),
        value: buildDisplayingPlate(dto.licensePlate.plate),
      },
      dto.licensePlate.pricingCategory
        ? {
            type: 'string',
            id: `pricing-category-${index}`,
            label: _tg('field.pricingCategory'),
            value: dto.licensePlate.pricingCategory,
          }
        : undefined,
      dto.tariffCode
        ? {
            type: 'string',
            id: `tariff-code-${index}`,
            label: _tg('field.pricing.tariffCode'),
            value: dto.tariffCode,
          }
        : undefined,
      {
        type: 'string',
        id: `startDatetime-${index}`,
        label: _tg('field.date.dateOfValidityStart'),
        value: formatDatetime({
          datetime: dto.startDatetime,
          withSeconds: true,
        }),
      },
      {
        type: 'string',
        id: `endDatetime-${index}`,
        label: _tg('field.date.dateOfValidityEnd'),
        value: formatDatetime({ datetime: dto.endDatetime, withSeconds: true }),
      },
      {
        type: 'string',
        id: `price-${index}`,
        label: _tg('commons.tariff'),
        value: formatCtsPrice(dto.price),
      },
    ]),
  };
};

const DownloadProofDocumentButton = ({
  ticket,
}: {
  ticket: ParkingRightWithVersionsDTO;
}): JSX.Element => {
  const [downloading, setDownloading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const download = async () => {
    if (!ticket.licensePlate.plate) {
      return;
    }

    try {
      setDownloading(true);
      const blob = await getProofOfPayment(
        ticket.ticketId,
        ticket.licensePlate.plate
      );
      const file = new File(
        [blob],
        `${_tg('tefps.tickets.export.proofDocumentName')}_${
          ticket.ticketId
        }.pdf`
      );
      downloadFile(file);
      setErrorMessage(undefined);
    } catch (e) {
      setErrorMessage(
        (e as { json?: { message?: string } }).json?.message ?? ''
      );
    } finally {
      setDownloading(false);
    }
  };

  if (downloading) {
    return <CircularProgress data-testid="proof-downloading" />;
  }

  return (
    <>
      {errorMessage && (
        <ErrorBlock
          message={
            errorMessage.length
              ? errorMessage
              : _tg('tefps.tickets.errors.duringDownload')
          }
        />
      )}
      <BoButton
        disabled={!ticket.licensePlate.plate}
        label={_tg('action.pdf')}
        onClick={download}
      />
    </>
  );
};

export const TicketSummary = connect(state => ({
  isParkingMeterEnabled: getConfigState(state).modulesConfiguration.parkingMeter
    .enabled,
}))(
  ({
    ticket,
    reload,
    onUpdate,
    isParkingMeterEnabled,
  }: {
    ticket: ParkingRightWithVersionsDTO;
    reload: (ticketId: string) => void;
    onUpdate: (msg: string) => void;
    isParkingMeterEnabled: boolean;
  }): JSX.Element => {
    const showDownloadProofDocumentButton =
      isParkingMeterEnabled && ticket.appClientId === 'cvfm-parking-meter';

    return (
      <Card className="no-padding">
        <div className="control-overview-block__header">
          <div className="text-bold">
            {_tg(
              'tefps.Control.List.ControlDetail.index.element.controlSynthesis'
            )}
          </div>
          <div>
            {ticket.ticketId}
            <CopyValueButton
              value={ticket.ticketId}
              iconStyle={{ height: 15, color: 'white' }}
              spanStyle={{ marginLeft: 5 }}
            />
          </div>
        </div>

        <div className="control-overview-block">
          <FakeInputBlock
            title={_tg('field.device.type')}
            value={ticket.type}
          />
          <FakeInputBlock title={_tg('field.source')} value={ticket.source} />
          {ticket.fineLegalId && (
            <FakeInputBlock
              title={_tg('commons.fps')}
              value={<FpsNumber fpsId={ticket.fineLegalId} />}
            />
          )}
          <FakeInputBlock
            title={_tg('field.address.zone')}
            value={ticket.zoneId}
          />
          {ticket.parkId && (
            <FakeInputBlock
              title={_tg('field.address.sector')}
              value={ticket.parkId}
            />
          )}
          <FakeInputBlock
            title={_tg('field.vehicle.plate')}
            value={
              <PlateRow
                ticketId={ticket.ticketId}
                licensePlate={ticket.licensePlate}
                reload={reload}
                onUpdate={onUpdate}
              />
            }
          />
          {ticket.vehicleCategory && (
            <FakeInputBlock
              title={_tg('field.vehicleCategory')}
              value={ticket.vehicleCategory}
            />
          )}
          {ticket.licensePlate.pricingCategory && (
            <FakeInputBlock
              title={_tg('field.pricingCategory')}
              value={ticket.licensePlate.pricingCategory}
            />
          )}
          {ticket.tariffCode && (
            <FakeInputBlock
              title={_tg('field.pricing.tariffCode')}
              value={ticket.tariffCode}
            />
          )}
          <FakeInputBlock
            title={_tg('tefps.filters.rapo.receptionDate')}
            value={<Date datetime={ticket.dateOfReceipt} withSeconds />}
          />
          <FakeInputBlock
            title={_tg('field.date.creation')}
            value={<Date datetime={ticket.creationDatetime} withSeconds />}
          />
          <FakeInputBlock
            title={_tg('field.date.dateOfValidityStart')}
            value={<Date datetime={ticket.startDatetime} withSeconds />}
          />
          <FakeInputBlock
            title={_tg('field.date.dateOfValidityEnd')}
            value={<Date datetime={ticket.endDatetime} withSeconds />}
          />
          {ticket.cancelDatetime && (
            <FakeInputBlock
              inputStyle={{ color: 'red' }}
              title={_tg('field.date.cancelDate')}
              value={<Date datetime={ticket.cancelDatetime} withSeconds />}
            />
          )}
          <FakeInputBlock
            title={_tg('commons.tariff')}
            inputStyle={{ border: `1px solid ${BKG_PINK}`, fontWeight: 'bold' }}
            value={<Price price={ticket.price} />}
          />
        </div>

        {showDownloadProofDocumentButton && (
          <div style={{ padding: '8px' }}>
            <DownloadProofDocumentButton ticket={ticket} />
          </div>
        )}
      </Card>
    );
  }
);

type Props = {
  ticket: ParkingRightWithVersionsDTO;
  reload: (ticketId: string) => void;
  onUpdate: (msg: string) => void;
};

const TicketPage = ({ ticket, reload, onUpdate }: Props): JSX.Element => {
  return (
    <Flex width100 justifyContent="center" className="control-page">
      <Container className="width-60">
        <Flex className="navigation-bar" justifyContent="space-between">
          <div className="navigation-buttons">
            <ReturnButton
              href="#/tickets/list"
              label={_tg('action.backToList')}
            />
          </div>
        </Flex>

        <TicketSummary ticket={ticket} reload={reload} onUpdate={onUpdate} />

        <VersionsDisplayer
          versions={ticket.versions
            .map((version, i) => parkingRightDTOToVersion(version, i))
            .reverse()}
        />
      </Container>
    </Flex>
  );
};

export default TicketPage;
